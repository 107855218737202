import Header from "../components/header";
import Footer from "../components/footers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/esm/Table";
import logo1 from "../assert/images/c_logo1.png";
import logo2 from "../assert/images/c_logo2.png";
import logo3 from "../assert/images/c_logo3.png";
import ASR from "../assert/images/ASRCertification.png";
import ISO from "../assert/images/iso.png";
import ISO1 from "../assert/images/iso20000.png";
import { Helmet } from "react-helmet";
import Partner from "../assert/images/microsoft_gold_certified.png";

function AboutByteForza() {
  return (
    <div>
      <Helmet>
        <title>About ByteForza</title>
        <meta
          name="ByteForza is a proudly minority owned SBA 8(a) certified, woman owned small business that enables business to become more agile and
          innovative through a comprehensive portfolio of services
          that addresses Digital transformation, Cloud Transformation,
          end to end DevOps workflows and Data Analytics."
          content=""
        />
      </Helmet>
      <Header />
      <div className="AboutCertification">
        <div className="container">
          <Row>
            <Col>
              <img src={logo1} alt="logo" />
            </Col>
            <Col>
              <img src={logo3} alt="logo" />
            </Col>
            <Col>
              <img src={logo2} alt="logo" />
            </Col>
            <Col>
              <img
                src={ASR}
                alt="ASR Certification Verfication"
                title="ASR Certification Verfication"
              />
            </Col>
            <Col>
              <img src={ISO} alt="ISO 27001:2013" title="ISO 27001:2013" />
            </Col>
            <Col>
              <img src={ISO1} alt="ISO 20000" title="ISO 20000" />
            </Col>
            <Col>
              <img src={Partner} alt="ISO 20000" title="ISO 20000" />
            </Col>
          </Row>
        </div>
      </div>
      <div class="subpage">
        <section>
          <div class="container">
            <div class="row d-flex align-items-center">
              <div class="col-sm-12 col-md-12 col-lg-7">
                <div class="mr-md-5 mr-lg-5">
                  <h1>Dream Big. We Make IT a Reality</h1>
                  <p>
                    ByteForza is a proudly minority owned SBA 8(a) certified,
                    woman owned small business that enables business to become
                    more agile and innovative through a comprehensive portfolio
                    of services that addresses Digital transformation, Cloud
                    Transformation, end to end DevOps workflows and Data
                    Analytics.
                  </p>
                  <p class="mb-3">
                    Whether you want to create a new awesome product or
                    transform your IT infrastructure to cloud or modernize your
                    application or derive intelligence from data - ByteForza can
                    get you there.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-5" data-aos-delay="200">
                <img
                  alt="about_img"
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/about.jpg")}
                />
              </div>
            </div>
          </div>
        </section>
        <section class="what_we_do">
          <div class="container">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-sm-6 col-md-6">
                <h1 class="text-white m-0 xs-mb-30">
                  There are two different ways <br />
                  to engage ByteForza
                </h1>
              </div>
              <div class="col-12 col-sm-6 col-md-6" data-aos-delay="200">
                <h3>Enable & Transform</h3>
                <p class="mb-5">
                  ByteForza is a proudly woman and minority owned small business
                  that enables business to become more agile and innovative by
                  offering a wide range of services. We enable our customers
                  into cloud adoption to reduce cost and increase operational
                  efficiency, inculcate DevSecOps culture to deliver reliable
                  software faster, transform business to adapt to constantly
                  changing environment using modern digital transformation
                  solutions and help increase bottom-line through the data
                  analytics
                </p>
                <h3>Deliver & Manage</h3>
                <p class="m-0">
                  ByteForza has expertise in handling end to end application
                  projects from conception to implementation and have experience
                  in delivering software and software products using the
                  cutting-edge technologies and by adopting agile and lean
                  principles. We manage your end to end IT.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container">
            <h1 class="text-center mb-5">Advantage</h1>
            <div class="row">
              <div
                class="col-12 col-sm-6 col-md-6 xs-mb-30"
                data-aos-delay="200"
              >
                <div class="d-flex mb-4">
                  <div class="no">
                    <div class="no-holder round-list-style">01</div>
                  </div>
                  <div class="list-text-item general-section-content ml-3 mt-1">
                    Excellent track record of IT Systems implementation and
                    sustenance across multiple industry domains
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="no">
                    <div class="no-holder round-list-style">02</div>
                  </div>
                  <div class="list-text-item general-section-content ml-3 mt-1">
                    Consistent, reputable and high-quality results grounded in
                    well-defined processes
                  </div>
                </div>
                <div class="d-flex">
                  <div class="no">
                    <div class="no-holder round-list-style">03</div>
                  </div>
                  <div class="list-text-item general-section-content ml-3 mt-1">
                    Expertise in use of modern, industry best practices and
                    standards including Agile, DevSecOps and Open Source
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6">
                <div class="d-flex mb-4">
                  <div class="no">
                    <div class="no-holder round-list-style">04</div>
                  </div>
                  <div class="list-text-item general-section-content ml-3 mt-1">
                    Trained and experienced team who are passionate about what
                    we do
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="no">
                    <div class="no-holder round-list-style">05</div>
                  </div>
                  <div class="list-text-item general-section-content ml-3 mt-1">
                    Proven process, templates and tools for faster time to
                    market
                  </div>
                </div>
                <div class="d-flex">
                  <div class="no">
                    <div class="no-holder round-list-style">06</div>
                  </div>
                  <div class="list-text-item general-section-content ml-3 mt-1">
                    Have provided Architectural and design solutions for
                    multiple customers
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fContract">
          <div class="container">
            <h1 className="text-center mb-5">NAICS/SAM/CAGE</h1>
            <Row>
              <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                <h2>
                  NAICS Code{" "}
                  <small>North American Industry Classification System</small>
                </h2>
                <Table className="tbl">
                  <thead>
                    <tr>
                      <td>511199</td>
                      <td>All Other Publishers</td>
                    </tr>
                    <tr>
                      <td>511210 </td>
                      <td>Software Publishers</td>
                    </tr>
                    <tr>
                      <td>517911</td>
                      <td>Telecommunications Resellers</td>
                    </tr>
                    <tr>
                      <td>518210</td>
                      <td>Data Processing, Hosting, and Related Services</td>
                    </tr>
                    <tr>
                      <td>519130</td>
                      <td>
                        Internet Publishing and Broadcasting and Web Search
                        Portals
                      </td>
                    </tr>
                    <tr>
                      <td>541330</td>
                      <td>Engineering Services</td>
                    </tr>
                    <tr>
                      <td>541511</td>
                      <td>Custom Computer Programming Services</td>
                    </tr>
                    <tr>
                      <td>541512</td>
                      <td>Computer Systems Design Services</td>
                    </tr>
                    <tr>
                      <td>541513</td>
                      <td>Computer Facilities Management Services</td>
                    </tr>
                    <tr>
                      <td>541519</td>
                      <td>Other Computer Related Services</td>
                    </tr>
                    <tr>
                      <td>541611</td>
                      <td>
                        Administrative Management and General Management
                        Consulting Services
                      </td>
                    </tr>
                    <tr>
                      <td>541613</td>
                      <td>Marketing Consulting Services</td>
                    </tr>
                    <tr>
                      <td>541618</td>
                      <td>Other Management Consulting Services</td>
                    </tr>
                    <tr>
                      <td>541620</td>
                      <td>Environmental Consulting Services</td>
                    </tr>
                    <tr>
                      <td>541690</td>
                      <td>
                        Other Scientific and Technical Consulting Services
                      </td>
                    </tr>
                    <tr>
                      <td>541990</td>
                      <td>
                        All Other Professional, Scientific, and Technical
                        Services
                      </td>
                    </tr>
                    <tr>
                      <td>562910</td>
                      <td>Remediation Services</td>
                    </tr>
                  </thead>
                </Table>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                <h2>Unique Entity ID</h2>
                <p>X8A9AGVPYL78</p>
                <h2>
                  CAGE Code <small>Commercial and Government Entity</small>
                </h2>
                <p>8LED5</p>
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default AboutByteForza;

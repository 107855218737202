import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import menuConfig from "../json/menu";

function Digitaltransformation() {
  return (
    <div className="hComponent">
      <div className="hComponent_container">
        <div className="hComponent_Left">
          <h1 className="title">Our Services</h1>
          <p className="subText">
            Our Services are delivered by our team with years of experience who
            are passionate about developing business.
          </p>
        </div>
        <div className="hComponent_Right">
          <div className="hComponentList">
            {menuConfig.dTransformation.map((dItem) => (
              <div key={dItem.id} className="hComponentListItem">
                <img src={dItem.imgSource} />
                <h2>{dItem.title}</h2>
                <p className="subText">{dItem.des}</p>
                <a className="siteLink" href={dItem.url}>
                  Know More
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    // <div className="DTrans">
    //   <div className="DTransHeader">
    //     <h1 className="title">Digital Transformation</h1>
    //     <p>
    //       Our Solutions are delivered by our team with years of experience who{" "}
    //       <br />
    //       are passionate about developing business.
    //     </p>
    //   </div>
    //   <div className="container">
    //     <Row>
    //       {menuConfig.dTransformation.map((dItem) => (
    //         <Col xs sm="12" xxl="4" lg="4" md="4">
    //           <div key={dItem.id} className="DTransListItem">
    //             <img src={dItem.imgSource} />
    //             <h2>{dItem.title}</h2>
    //             <p className="subText">{dItem.des}</p>
    //             <a href={dItem.url}>Know More</a>
    //           </div>
    //         </Col>
    //       ))}
    //     </Row>
    //   </div>
    // </div>
  );
}

export default Digitaltransformation;

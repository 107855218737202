import { Carousel } from "react-bootstrap";
import { subBanner } from "../json/banner";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function SubBanner() {
  const { pathname: currentRoute } = useLocation();
  const [item, setItem] = useState({});

  useEffect(() => {
    const data = subBanner.filter((e) => e.pathName === currentRoute);
    setItem(data[0]);
  }, []);
  return (
    <div
      className="sub_banner d-flex byteforza_SubBg"
      style={{ backgroundImage: `url(${item.imgSource})` }}
    >
      <div className="container text-center">
        <h1>{item.title}</h1>
        <p>{item.description}</p>
      </div>
    </div>
  );
}

export default SubBanner;

import Header from "../components/header";
import Digitaltransformation from "../components/digitaltransformation";
import Footer from "../components/footers";
import Customers from "../components/customers";
import { Helmet } from "react-helmet";

function Homepage() {
  return (
    <div>
      <Helmet>
        <title>ByteForza Technologies</title>
        <meta
          name="U.S Small Business Administration SBA 8(a) Certified Woman Owned Business with Digital, Business, and Technology Transformation Expertise"
          content=""
        />
      </Helmet>
      <Header />
      <Digitaltransformation />
      <Customers />
      <Footer />
    </div>
  );
}

export default Homepage;

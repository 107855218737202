import Header from "../components/header";
import Footer from "../components/footers";
import { Helmet } from "react-helmet";

function AppDevModernization() {
  return (
    <div>
      <Helmet>
        <title>Application Development & Modernization</title>
        <meta
          name="Accelerate your Business Growth by Building Cloud-Native Applications"
          content=""
        />
      </Helmet>
      <Header />
      <div class="subpage">
        <section>
          <div class="container">
            <div class="row d-flex">
              <div class="col-12">
                <p class="content_des" data-aos="fade-up" data-aos-delay="100">
                  Technology evolves at a rapid phase and businesses need to
                  leverage latest technology to stay ahead of their competition.
                  Maintaining legacy code with technical debt is an expensive
                  operation and takes time away from core business mission.
                  Legacy code changes are often notoriously difficult to update
                  and expensive and on the other hand, not updating the code
                  might leave you exposed to liability. Today’s Customer base
                  expects new features to be released faster than ever before.
                  We can address all of these challenges by our end-to-end
                  Application Development & Modernization Services focusing on
                  usability, security, and quality. Our team follows industry
                  leading practices in software development including Agile and
                  DevSecOps to ensure its performance is efficient with security
                  built into each phase of the Software Development Lifecycle
                  (SDLC). We recognize the importance of cloud-native
                  application development in digital transformation and this
                  includes building an application from the scratch or
                  modernizing an application to fit into cloud.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white">
          <div class="container" data-aos="fade-up">
            <div class="row d-flex">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="mr-md-3 mr-lg-3">
                  <h1>Cloud-Native Development</h1>
                  <p>
                    Cloud-native development is an essential component of
                    delivering business value by enabling quick build and
                    delivery of applications in response to customer needs.
                    Cloud-native applications are purpose built for the cloud.
                    Cloud platform offers instant provisioning, easy scale-out
                    and flexibility of the infrastructure and this enables Agile
                    teams to rapidly build and deploy applications.{" "}
                  </p>
                  <p class="mb-3 mb-md-5 mb-lg-0">
                    Our development team leverages open standards for
                    interoperability in developing cloud-native applications and
                    we follow the industry standards 12-Factor App principles.
                    Based on our prior experience, we have created a repository
                    of reference implementation on containerized platforms such
                    as Docker & Kubernetes as well as PaaS models on Azure and
                    AWS. Our team also leverages “Infrastructure-as-Code – IaC”
                    to automate the provisioning of cloud infrastructure and
                    this includes using Azure ARM templates, Cloud Formation
                    templates and Terraform.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/app_development_1.jpg")}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container">
            <div class="row d-flex" data-aos="fade-up">
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/app_development_2.jpg")}
                />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="ml-md-0 ml-lg-3 ml-sm-0 mt-md-5 mt-lg-0">
                  <h1>Application Modernization</h1>
                  <p>
                    Is your business burdened due to high maintenance costs of
                    legacy code? Waiting on a ticking time bomb is not an
                    option. We can help alleviate your problem by enabling you
                    to get started with the journey of modernizing your
                    application portfolio. Take advantage of the latest
                    application trends and innovations by leveraging flexible,
                    agile architectures that improve performance and drive
                    market growth.
                  </p>
                  <p class="mb-3 mb-lg-0">
                    We can help align your application portfolio landscape from
                    traditional to a more modern, optimized and user-centric
                    architecture. We understand that there is no universal
                    strategy for application modernization, and different
                    strategies are needed for different scenarios. We follow the
                    6 R’s of application modernization strategy namely: Rehost,
                    Replatform, Refactor, Rearchitect, Rebuild, and Replace. Our
                    team can help you understand the risks and rewards, effort
                    and impact of each of these strategies and we can help you
                    choose the most optimal strategy that will enable you to
                    derive the maximum business value while finding ways to
                    mitigate your risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white">
          <div class="container">
            <div class="row d-flex" data-aos="fade-up">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="mr-md-5 mr-lg-3">
                  <h1>Beat your competition by accelerating time-to-market</h1>
                  <p>
                    Getting to market quickly is paramount in today’s
                    competitive digital landscape. In order to beat your
                    competition, you need to keep your application portfolio
                    fresh by keeping up to date with new features. You should
                    have the capability to release new features in days and not
                    in months.{" "}
                  </p>
                  <p class="mb-3 mb-md-5 mb-lg-0">
                    We release early and often & with higher quality of code and
                    our team builds and deploys new features rapidly by
                    leveraging industry best practices – Agile, DevSecOps,
                    Containerization, Microservices and
                    Infrastructure-as-Code(IaC).{" "}
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/app_development_3.jpg")}
                />
              </div>
            </div>
          </div>
        </section>
        <section class="tools_tech">
          <div class="tools_container">
            <h1
              class="text-center mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Tools & Technologies
            </h1>
            <div class="row">
              <div
                class="col-lg-4 col-md-12"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h2>Development Tools / IDE</h2>
                <div class="grid-row">
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/visual_studio.png")}
                    />
                    <h6>Visual Studio</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/eclipse.png")} />
                    <h6>Eclipse</h6>
                  </div>
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/android_studio.png")}
                    />
                    <h6>Android Studio</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/xamarin.png")} />
                    <h6>Xamarin</h6>
                  </div>
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/apache_cordova.png")}
                    />
                    <h6>Apache Cordova</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/xcode.png")} />
                    <h6>Xcode</h6>
                  </div>
                </div>
                <h2>Source Control Repository</h2>
                <div class="grid-row">
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/azure_repos.png")}
                    />
                    <h6>Azure Repos</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/github.png")} />
                    <h6>GitHub</h6>
                  </div>
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/bitbucket.png")}
                    />
                    <h6>Bitbucket</h6>
                  </div>
                </div>
                <h2>CI/CD Tools</h2>
                <div class="grid-row mb-0 mb-md-3 xs-mb-30">
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/azure_devops.png")}
                    />
                    <h6>Azure Devops</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/jenkins.png")} />
                    <h6>Jenkins</h6>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-12"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h2>Application Framework</h2>
                <div class="grid-row">
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/dotNet.png")} />
                    <h6>.Net</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/java.png")} />
                    <h6>Java</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/nodejs.png")} />
                    <h6>Node Js</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/angular.png")} />
                    <h6>Angular</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/swift.png")} />
                    <h6>Swift</h6>
                  </div>
                </div>
                <h2>Containerization</h2>
                <div class="grid-row mb-0 mb-md-3 xs-mb-30">
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/docker_logo.png")}
                    />
                    <h6>Docker</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/acs.png")} />
                    <h6>ACS</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/aecs.png")} />
                    <h6>AWS ECS</h6>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-12"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2>Public Cloud</h2>
                <div class="grid-row">
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/azure_logo.png")}
                    />
                    <h6>Azure</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/aws_logo.png")} />
                    <h6>AWS</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/GCP.png")} />
                    <h6>GCP</h6>
                  </div>
                </div>
                <h2>Container Orchestration</h2>
                <div class="grid-row">
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/aks.png")} />
                    <h6>AKS</h6>
                  </div>
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/kubernetes.png")}
                    />
                    <h6>kubernetes</h6>
                  </div>
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/docker_swarm.png")}
                    />
                    <h6>Docker Swarm</h6>
                  </div>
                </div>
                <h2>Testing</h2>
                <div class="grid-row mb-0">
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/eclipse.png")} />
                    <h6>Eclipse</h6>
                  </div>
                  <div class="grid-item">
                    <img
                      src={require("../assert/images/pages/jira_xray.png")}
                    />
                    <h6>Jira Xray</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/maven.png")} />
                    <h6>Maven</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/testng.png")} />
                    <h6>TestNg</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/selenium.png")} />
                    <h6>Selenium</h6>
                  </div>
                  <div class="grid-item">
                    <img src={require("../assert/images/pages/jmeter.png")} />
                    <h6>Jmeter</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default AppDevModernization;

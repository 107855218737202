import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import FormTextField from "../components/formTextField";
import Spinner from "react-bootstrap/Spinner";
import emailConfig from "../json/config.json";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from 'libphonenumber-js';

const validateUniversalPhoneNumber = async (value) => {
  if (!!value) {
    if (/^\+[0-9]*$/.test(value)) {
      const validatePhoneNumber = await isValidPhoneNumber(value);
      return validatePhoneNumber;
    }
    return false;
  }
  return true;
};

const schema = yup.object().shape({
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name"),
  companyName: yup.string().required("Please enter company name"),
  email: yup
    .string()
    .required("Please enter email")
    .email("Please enter valid email"),
  phoneNumber: yup
    .string()
    .required("Please enter a valid phone Number with country code")
    .test(
      "formatCheck",
      "Please enter a valid phone Number with country code",
      validateUniversalPhoneNumber
    ),
  description: yup.string().required("Please enter description"),
});

const UserForm = () => {
  const [isloading, setIsLoading] = React.useState(false);

  return (
    <Formik
      initialErrors={{}}
      initialTouched={{}}
      initialValues={{
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        description: "",
      }}
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        try {
          setIsLoading(true);
          const mailsent = await emailjs.send(
            `${emailConfig.emailJs_config.EMAILJS_SERVICE_ID}`,
            `${emailConfig.emailJs_config.EMAILJS_TEMPLATE_ID}`,
            values,
            `${emailConfig.emailJs_config.EMAILJS_PUBLIC_KEY}`
          );
          if (mailsent.status === 200) {
            setIsLoading(false);
            toast.success(
              "Email sent successfully. Our team will reach out you soon. Thank you"
            );
            resetForm();
          } else {
            toast.error("Something went wrong. Please try again later");
          }
        } catch (e) {
          console.log(e);
          setIsLoading(false);
          toast.error("Something went wrong. Please try again later");
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        handleReset,
        values,
        touched,
        errors,
      }) => (
        <Form
          noValidate
          onSubmit={handleSubmit}
          onReset={handleReset}
          className="cForm"
        >
          <FormTextField
            controlId="validationFormik01"
            className="formControl"
            label="First name"
            type="text"
            name="firstName"
            value={values.firstName}
          />
          <FormTextField
            controlId="validationFormik02"
            className="formControl"
            label="Last name"
            type="text"
            name="lastName"
            value={values.lastName}
          />
          <FormTextField
            controlId="validationFormik03"
            className="formControl"
            label="Company name"
            type="text"
            name="companyName"
            value={values.companyName}
          />
          <FormTextField
            controlId="validationFormik04"
            className="formControl"
            label="Email"
            type="text"
            name="email"
            value={values.email}
          />
          <FormTextField
            controlId="validationFormik05"
            className="formControl"
            label="Phone number"
            type="text"
            name="phoneNumber"
            value={values.phoneNumber}
          />
          <FormTextField
            controlId="validationFormik06"
            className="formControl"
            label="Description"
            type="textArea"
            name="description"
            rows={5}
            value={values.description}
          />

          <div className="pt-1 pb-1">
            <Button
              type="submit"
              className="btn btn-primary btn-sm me-xxl-2"
              style={{ minWidth: 50 }}
              disabled={isloading}
            >
              {isloading ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                <>Submit</>
              )}
            </Button>
            &ensp;
            <Button
              type="reset"
              className="btn btn-secondary btn-sm"
              style={{ minWidth: 50 }}
              disabled={isloading}
            >
              {isloading ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                <>Cancel</>
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import epa from "../assert/images/epa.png";
import nationalguard from "../assert/images/nationalguard.png";
import reserve from "../assert/images/reserve.png";
import engineers from "../assert/images/engineers.png";
import guard from "../assert/images/guard.png";
import airng from "../assert/images/airng.png";
import nawcad from "../assert/images/nawcad.png";
import G9 from "../assert/images/g9.png";

function Customers() {
  return (
    <div className="customers">
      <Container>
        <h1 className="text-center title">Customers We Serve.</h1>
        <Row className="mt-5">
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img
                src={guard}
                alt="US Army Material Command"
                title="US Army Material Command"
              />
            </div>
          </Col>
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img
                src={engineers}
                alt="US Army Corps of Engineers"
                title="US Army Corps of Engineers"
              />
            </div>
          </Col>
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img
                src={reserve}
                alt="US Army Reserve"
                title="US Army Reserve"
              />
            </div>
          </Col>
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img
                src={airng}
                alt="US Air National Guard"
                title="US Air National Guard"
              />
            </div>
          </Col>
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img
                src={epa}
                alt="Environmental Protection Agency"
                title="Environmental Protection Agency"
              />
            </div>
          </Col>
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img
                src={nationalguard}
                alt="US Army National Guard"
                title="US Army National Guard"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5 jContent">
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img src={nawcad} alt="NAWCAD" title="NAWCAD" />
            </div>
          </Col>
          <Col xs="6" lg="2" md="4" className="project-grid-nol">
            <div className="project-grid-img">
              <img src={G9} alt="Army G9" title="Army G9" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Customers;

import logo1 from "../assert/images/c_logo1.png";
import logo2 from "../assert/images/c_logo2.png";
import logo3 from "../assert/images/c_logo3.png";
import ASR from "../assert/images/ASRCertification.png";
import ISO from "../assert/images/iso.png";
import ISO1 from "../assert/images/iso20000.png";
import Partner from "../assert/images/microsoft_gold_certified.png";

function Certification() {
  return (
    <div className="certification">
      <img src={logo1} alt="logo" />
      <img src={logo3} alt="logo" />
      <img src={logo2} alt="logo" />
      <img
        src={ASR}
        alt="ASR Certification Verfication"
        title="ASR Certification Verfication"
      />
      <img src={ISO} alt="ISO 27001:2013" title="ISO 27001:2013" />
      <img src={ISO1} alt="ISO 20000" title="ISO 20000" />
      <img src={Partner} alt="ISO 20000" title="ISO 20000" />
    </div>
  );
}

export default Certification;

import Header from "../components/header";
import Footer from "../components/footers";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>PrivacyPolicy</title>
        <meta name="ByteForza Careers" content="" />
      </Helmet>
      <Header />

      <div className="subpage">
        <section class="container">
          {" "}
          <p>
            ByteForza Inc is firmly committed to protecting your privacy. We
            hope the following policy will help you understand how ByteForza
            collects, uses, and safeguards the personal information you provide
            on our Web site.
          </p>
          <p>
            <strong>Ownership:</strong>
            <br />
            <p>
              The ByteForza Inc web site located at{" "}
              <a href="https://www.byteforza.com/" target="blank">
                www.byteforza.com
              </a>{" "}
              (the “Site”) is owned by{" "}
              <a href="https://www.byteforza.com/" target="blank">
                ByteForza Inc
              </a>
              .
            </p>
          </p>
          <p>
            <strong>Overview:</strong>
            <br />
            <p>
              You can visit ByteForza on the World Wide Web without telling us
              who you are or revealing any information about yourself. In
              general, using our site as a site visitor may be considered
              anonymous: we do not engage in any activity that will personally
              identify our site visitors. We use your IP address to help
              diagnose problems with our server, to administer our Web site, and
              to refine our site based on which features people use most often.
              Your IP address is also used to help identify you and to gather
              broad demographic information.{" "}
            </p>
          </p>
          <p>
            <strong>Cookies:</strong>
            <br />
            <p>
              A Cookie is a message given to a Web browser by a Web server. The
              browser stores the message in a text file. The message is then
              sent back to the server each time the browser requests a page from
              the server. We analyse it to determine what is most effective
              about our site, to help us identify ways to improve it and to
              determine how we can tailor to make it more effective. We may also
              use data for other purposes, which we would describe to you at the
              point we collect the information.
            </p>
          </p>
          <p>
            <strong>Hyperlinks on this Site:</strong>
            <br />
            <p>
              This website contains links to other websites. ByteForza is not
              responsible for the privacy practices or the contents of such
              websites.
            </p>
          </p>
          <p>
            <strong>Sharing:</strong>
            <br />
            <p>
              ByteForza Inc., may share aggregated demographic information with
              our partners. This is not linked to any personal information that
              can identify an individual person.
            </p>
          </p>
          <p>
            <strong>How we use the information we gather:</strong>
            <br />
            <p>
              We use personally identifiable information in the following ways:
              <ul>
                <li>
                  To provide you with information about virus alerts, services,
                  newsletters, offers, informative emails.
                </li>
                <li>
                  To help you quickly find our services or product information
                  important to you.
                </li>
                <li>
                  To assist us in creating better services to meet your needs.
                </li>
                <li>
                  To assist us in creating content that is relevant to you.
                </li>
              </ul>
            </p>
          </p>
          <p>
            <strong>Contacting the Web Site:</strong>
            <br />
            <p>
              If you have any questions about this privacy statement, you can
              contact at{" "}
              <a href="mailto:Support@byteforza.com">support@byteforza.com</a>.
            </p>
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

import Header from "../components/header";
import Footer from "../components/footers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";

function Team() {
  return (
    <div>
      <Helmet>
        <title>Our Leadership Team</title>
        <meta name="" content="" />
      </Helmet>
      <Header />
      <section>
        <div className="container">
          <Row>
            <Col xs="12" sm="12" md="6">
              <div className="teamItem">
                <div className="left">
                  <img
                    alt="vimalaMahesh"
                    class="pImage"
                    src={require("../assert/images/pages/vimala.png")}
                  />
                </div>
                <div className="right">
                  <h2 class="profile_team">
                    Vimalaa Mahesh <small>President and CEO </small>
                  </h2>
                  <p>
                    As the President and CEO of ByteForza Inc. , I am
                    responsible for the overall strategic direction of the
                    company.This includes Planning and Execution, Operations,
                    Profit & Loss responsibility on our Customer Accounts, and
                    Delivery oversight of all ByteForza programs.
                  </p>
                  <p class="mb-3">
                    I have a Bachelor’s degree in Business Management. Before
                    launching ByteForza as a successful software services and
                    product development firm, I went through rigorous training
                    in Program Management & Technology domains. With my unique
                    combination of business and technology background, I've
                    successfully fueled ByteForza’ s growth for the past three
                    years by fostering key business relationships with my
                    customers and by creating innovative solutions to our
                    client's complex technological issues.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6">
              <div className="teamItem">
                <div className="left">
                  <img
                    alt="mahesh"
                    class="pImage"
                    src={require("../assert/images/pages/mahesh.png")}
                  />
                </div>
                <div className="right">
                  <h2 class="profile_team">
                    Mahesh Srinivasan <small>Chief Technology Officer </small>
                  </h2>
                  <p>
                    As the CTO of ByteForza Inc, I am responsible for leading
                    the company’s information technology & operations strategy
                    by driving the architecture and delivery of key IT solutions
                    to support ByteForza’s growing customer base. I am also
                    spearheading the Cloud, DevSecOps, Data Management, BI, RPA
                    & Cybersecurity Pratice Areas and R&D division within
                    ByteForza. I have helped grow these practices from a nascent
                    solution offering to a revenue generating business line.
                  </p>
                  <p>
                    I've held several senior management roles such as CTO,
                    Enterprise Architect and Director, Solution Delivery in the
                    past 20+ years enabling both Federal & Commercial customers
                    in their digital transformation journey.
                  </p>
                  <p class="mb-3">
                    I have a Master’s degree in Computer Science and Bachelor’s
                    in Engineering. When not at work, I enjoy spending time with
                    my wife and two kids. I am an avid reader; playing badminton
                    and chess have been my lifelong hobbies.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Team;

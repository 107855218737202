import Header from "../components/header";
import Footer from "../components/footers";
import Page from "../assert/images/page404.png";
import { Helmet } from "react-helmet";

function Careers() {
  return (
    <div>
      <Helmet>
        <title>Careers</title>
        <meta name="ByteForza Careers" content="" />
      </Helmet>
      <Header />

      <div className="text-center page ptb-120">
        <h3>We’re always on the lookout for talent.</h3>
        <p>
          Please send your resume to{" "}
          <a href="mailto:careers@byteforza.com">careers@byteforza.com</a> and
          we will reach out you.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Careers;

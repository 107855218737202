import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../src/assert/style/style.scss";
import "../src/components/main.js";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import Homepage from "./pages/home";
import reportWebVitals from "./reportWebVitals";
import AppDevModernization from "./pages/app_dev_modernization";
import CloudServices from "./pages/cloud_services";
import DevSecOpsservices from "./pages/DevSecOps_services";
import BigData from "./pages/big_data_analytics";
import Testing from "./pages/Testing";
import RPA from "./pages/RPA";
import AboutByteForza from "./pages/About_ByteForza";
import Team from "./pages/team";
import EightA from "./pages/8a";
import GSASchedule from "./pages/GSA_Schedule";
import News from "./pages/News";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/Privacy_policy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GSAMAS from "./pages/GSA_MAS";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider
    breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
  >
    <ToastContainer
      style={{ width: "100vw", top: 0 }}
      position="top-center"
      autoClose="5000"
      hideProgressBar={true}
      closeButton={false}
      theme="colored"
    />
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route
            path="/app_dev_modernization"
            element={<AppDevModernization />}
          />
          <Route path="/cloud_services" element={<CloudServices />} />
          <Route path="/DevSecOps_services" element={<DevSecOpsservices />} />
          <Route path="/big_data_analytics" element={<BigData />} />
          <Route path="/Testing" element={<Testing />} />
          <Route path="/RPA" element={<RPA />} />
          <Route path="/About_ByteForza" element={<AboutByteForza />} />
          <Route path="/Meet_The_Team" element={<Team />} />
          <Route path="/8a" element={<EightA />} />
          <Route path="/GSA_Schedule_70" element={<GSASchedule />} />
          <Route path="/News_Events" element={<News />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/ContactUs" element={<Contact />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/GSAMAS" element={<GSAMAS />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </ThemeProvider>
);

// root.render(
//   <React.StrictMode>
//     <Router>
//       <Routes>
//         <Route path='/' exact element={<Homepage/>} />
//         <Route path='/app_dev_modernization' element={<AppDevModernization/>}/>
//       </Routes>
//     </Router>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

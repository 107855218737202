import Header from "../components/header";
import Footer from "../components/footers";
import Page from "../assert/images/page404.png";
import { Helmet } from "react-helmet";

function News() {
  return (
    <div>
      <Helmet>
        <title>News & Events</title>
        <meta name="ByteForza News & Events" content="" />
      </Helmet>
      <Header />
      <div className="text-center page ptb-120">
        {/* <h3>News & Events</h3> */}
        <p>News & Events Coming Soon...</p>
      </div>
      <Footer />
    </div>
  );
}

export default News;

import Applicationdevelopmentmodernization from "../assert/images/application_development_modernization.png";
import CloudServices from "../assert/images/cloud_services.png";
import DevSecOps from "../assert/images/DevSecOps.png";
import BigDataAnalytics from "../assert/images/BigDataAnalytics.png";
import Testing from "../assert/images/testing.png";
import RPA from "../assert/images/rpa.png";

import insights1 from "../assert/images/azure.jpg";
import insights2 from "../assert/images/devsecops.jpg";
import insights3 from "../assert/images/docker.jpg";

const menu = [
  { id: 1, name: "Home", url: "/" },
  {
    id: 2,
    name: "Services",
    child: [
      { id: 1, name: "App Dev & Modernization", url: "/app_dev_modernization" },
      { id: 2, name: "Cloud Services", url: "/cloud_services" },
      { id: 3, name: "DevSecOps", url: "/DevSecOps_services" },
      { id: 4, name: "Big Data and Analytics", url: "/big_data_analytics" },
      { id: 5, name: "Testing", url: "/Testing" },
      { id: 6, name: "RPA", url: "/RPA" },
    ],
  },
  {
    id: 3,
    name: "About Us",
    child: [
      { id: 1, name: "About ByteForza", url: "/About_ByteForza" },
      { id: 2, name: "Meet The Team", url: "/Meet_The_Team" },
    ],
  },
  {
    id: 4,
    name: "Contract Vehicles",
    child: [
      { id: 1, name: "8A", url: "/8a" },
      { id: 2, name: "GSA MAS", url: "/GSAMAS" },
    ],
  },
  { id: 5, name: "News/ Events", url: "/News_Events" },
  { id: 6, name: "Careers", url: "/Careers" },
  { id: 7, name: "Contact Us", url: "/ContactUs" },
];

const dTransformation = [
  {
    id: 1,
    imgSource: Applicationdevelopmentmodernization,
    title: "Application Development & Modernization",
    des: "Innovative App development, App Modernization, Cloud Native App development",
    url: "/app_dev_modernization",
  },
  {
    id: 2,
    imgSource: CloudServices,
    title: "Cloud Services",
    des: "Your preferred partner for Cloud Consulting, Implementation & Support and Managed Services in Azure & AWS.",
    url: "/cloud_services",
  },
  {
    id: 3,
    imgSource: DevSecOps,
    title: "DevSecOps",
    des: "Build and release secure, high quality code faster.Incorporate Security in DevOps",
    url: "/DevSecOps_services",
  },
  {
    id: 4,
    imgSource: BigDataAnalytics,
    title: "Big Data and Analytics",
    des: "Harness big data to drive informed business decisions.",
    url: "/big_data_analytics",
  },
  {
    id: 5,
    imgSource: Testing,
    title: "Testing",
    des: "Minimize cost, mitigate risk and augment user experience levels by offering quality software solutions.",
    url: "/Testing",
  },
  {
    id: 6,
    imgSource: RPA,
    title: "RPA",
    des: "Optimize Operational Expenses, improve productivity and enable digital transformation.",
    url: "/RPA",
  },
];

const insights = [
  {
    id: 1,
    imgSource: insights1,
    title: "Running Kubernetes on Azure",
    des: "Azure Kubernetes Service(AKS) makes it simple to deploy a managed Kubernetes cluster in Azure. AKS reduces the complexity and operational overhead of managing Kubernetes by offloading much of the at responsibility to Azure.",
    url: "/app_dev_modernization",
  },
  {
    id: 2,
    imgSource: insights2,
    title: "DevSecOps: Best way to secure your organization’s software assets.",
    des: "Digital Transformation has created significant changes in the way a business operates. However, this has created a key concern in organization's digital journey i.e., Securing Software Assets that constantly change with fast-paced Agile delivery to provide value business value.",
    url: "/cloud_services",
  },
  {
    id: 3,
    imgSource: insights3,
    title: "Deploying Docker on AWS",
    des: "AWS provides an elastic, secure, flexible, and developer-centric ecosystem that serves as an ideal platform for Docker deployments. There are two possibilities for container deployment  in AWS: AWS Elastic Beanstalk, and Amazon ECS.",
    url: "/DevSecOps_services",
  },
];

let menuConfig = {};
menuConfig.menu = menu;
menuConfig.dTransformation = dTransformation;
menuConfig.insights = insights;

export default menuConfig;

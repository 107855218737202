import logo from "../assert/images/byteforza_logo.png";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import menuConfig from "../json/menu";

function Bytenavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" id="navbarMain">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} alt="logo" className="navBrandLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {menuConfig.menu.map((menuitem) => {
              if (menuitem.child) {
                return (
                  <NavDropdown
                    key={menuitem.id}
                    title={menuitem.name}
                    id="collasible-nav-dropdown"
                  >
                    {menuitem.child.map((subitem) => {
                      return (
                        <NavDropdown.Item key={subitem.id} href={subitem.url}>
                          {subitem.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                );
              } else {
                return (
                  <Nav.Link key={menuitem.id} href={menuitem.url}>
                    {menuitem.name}
                  </Nav.Link>
                );
              }
            })}

            {/* {menu.map(menuitem => {
                                return menuitem.child?(
                                    <NavDropdown title={menuitem.name} id="collasible-nav-dropdown" align="end">
                                        {menuitem.child.map(subitem=>{
                                            return(
                                                <NavDropdown.Item href={subitem.url}>{subitem.name}</NavDropdown.Item>
                                            )
                                        })}
                                    </NavDropdown>
                                 ) : (
                                    <Nav.Link href={menuitem.url}>{menuitem.name}</Nav.Link>
                                  );
                            })
                        } */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Bytenavbar;

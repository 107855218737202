import SiteBanner from "./carousel";
import Bytenavbar from "./sitenavbar";
import Certification from "./certification";
import SubBanner from "./subBanner";
import { useLocation } from "react-router-dom";

function Header() {
  const { pathname: currentRoute } = useLocation();
  return (
    <div className="siteHeader">
      <Bytenavbar />
      {currentRoute == "/" || currentRoute == "/home" ? (
        <>
          <SiteBanner />
          <Certification />
        </>
      ) : (
        <SubBanner />
      )}
    </div>
  );
}

export default Header;

import { Carousel } from "react-bootstrap";
import { banner } from "../json/banner";

function SiteBanner() {
  return (
    <Carousel controls={true} indicators={false}>
      {banner.map((item) => (
        <Carousel.Item key={item.id}>
          <div
            style={{ backgroundImage: `url(${item.imgSource})` }}
            className="imgBanner full-screen"
          ></div>
          <Carousel.Caption>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default SiteBanner;

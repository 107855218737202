import Header from "../components/header";
import Footer from "../components/footers";
import { Helmet } from "react-helmet";

function CloudServices() {
  return (
    <div>
      <Helmet>
        <title>Cloud Services</title>
        <meta
          name="Today we are at the cusp of a major technology transformation in both Federal and Commercial sector."
          content=""
        />
      </Helmet>
      <Header />
      <div class="subpage">
        <section>
          <div class="container">
            <div class="row d-flex">
              <div class="col-12">
                <p class="content_des" data-aos="fade-up" data-aos-delay="100">
                  Your business needs to be flexible, agile and scalable in
                  order to beat your competition. Cloud addresses these business
                  needs by providing a platform to dynamically scale as per your
                  needs. By converting cap-ex to op-ex, cloud services enable
                  organizations to become more nimble and collaborative within
                  and across the enterprise boundary. Organizations both small
                  and large, need a comprehensive and clear Cloud roadmap
                  optimized to support business growth and aligned with business
                  goals in terms of performance, availability, flexibility,
                  scalability, security, and cost. We have worked extensively
                  with key stakeholders involved in the organization’s cloud
                  journey and this includes collaborating with both IT
                  executives and business leaders to ensure that the cloud
                  implementations drive the next stage of growth for their
                  respective organizations. Our end-to-end cloud services
                  includes Cloud Advisory & Strategy Consulting, Cloud-Native
                  Application Development, Cloud Migration, Cloud Optimization,
                  Cloud Monitoring and Management. Our comprehensive cloud
                  computing services will enable your organization to design,
                  develop and launch effective cloud-based applications. Our
                  in-depth knowledge of cloud services offered by different
                  service providers such as Microsoft Azure, AWS, and GCP,
                  streamlined processes and skilled cloud engineering team can
                  help achieve your business goals.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white">
          <div class="container">
            <div class="row d-flex" data-aos="fade-up">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="mr-md-3 mr-lg-3">
                  <h1>Cloud Advisory Services</h1>
                  <p>
                    With multitude of deployment options, services and solutions
                    in the cloud, it surely can get confusing to choose the
                    right strategy for your cloud adoption. Organizations often
                    spin their wheels in their cloud journey as they are new to
                    Cloud or do not have a solid understanding of how to
                    leverage the right cloud service and operate effectively in
                    cloud.
                  </p>
                  <p class="mb-3 mb-md-5 mb-lg-0">
                    Our Cloud Advisory Services is designed to provide
                    organizations with strategic guidance on their cloud
                    journey. Our forward-thinking approach provides customers
                    with an actionable cloud strategy and roadmap with long-term
                    benefits like accelerated time-to-market, cost reduction,
                    modernization opportunity with cloud-native development and
                    continuous innovation. Our strategy and roadmap will include
                    details about which candidate applications will be a best
                    fit for cloud, what will be the correct deployment
                    model(IaaS, PaaS or SaaS), which cloud services/technologies
                    will be appropriate for your applications, cloud pricing
                    model/TCO calculation to determine budget and cloud
                    migration process.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/cloud_services_1.jpg")}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container">
            <div class="row d-flex" data-aos="fade-up">
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/cloud_services_2.jpg")}
                />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="ml-md-0 ml-lg-3 ml-sm-0 mt-md-3 mt-lg-0">
                  <h1>Cloud Migration</h1>
                  <p>
                    On-premise IT capacity is hard to keep up with growing
                    business demand. In order to accommodate new business
                    capabilities, infrastructure needs to be freed up.
                  </p>
                  <p class="mb-3 mb-lg-0">
                    Our Cloud Migration Services cover migration of
                    infrastructure, applications, data, and business processes
                    of an organization to the cloud. As part of migration
                    services, we help identify areas of risk and opportunities
                    to improve the performance and availability of critical
                    business functions. We also look at historical usage
                    patterns and “right size” the infrastructure in the target
                    cloud environment for better ROI. Based on our vast
                    experience, our cloud engineering team has created a robust
                    Cloud Migration Framework which includes four phases:
                    Assessment, Planning & Solution Design, Migration Execution,
                    and Management. With our proven Cloud Migration Framework
                    and execution approach, we can provide immense value to your
                    organization by accelerating time to market, better ROI
                    realization, reduced op-ex costs, and automated provisioning
                    of infrastructure to reduce migration cycle time
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white">
          <div class="container" data-aos="fade-up">
            <div class="row d-flex">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="mr-md-3 mr-lg-3">
                  <h1>Cloud Managed Services</h1>
                  <p>
                    Every successful organization with cloud solutions needs to
                    maximize their cloud investment through 24 x 7 ongoing
                    management of infrastructure and databases, cost and
                    consumption optimization, performance and availability
                    monitoring and automation.
                  </p>
                  <p class="mb-3 mb-md-5 mb-lg-0">
                    We provide managed services across platforms and deployment
                    models. Our cloud managed services span across provisioning,
                    operations, disaster recovery, data replication,
                    availability checking & alerts, monitoring, security,
                    performance management, support and sustenance. Our services
                    adhere to well defined Service Level Agreements (SLAs) with
                    assured service levels. With our comprehensive cloud managed
                    services, we will ensure that your applications are
                    functioning smoothly and your enterprise operations are
                    effectively performing while continuously optimizing cloud
                    services.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/pages/cloud_services_3.jpg")}
                />
              </div>
            </div>
          </div>
        </section>
        <section class="features_box">
          <div class="features_container" data-aos="fade-up">
            <h1 class="text-center mb-5">Our Cloud Migration Framework</h1>
            <div class="row no-gutters">
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-header">Assessment</div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/technical.png")}
                          />
                          Technical & Functional
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/security.png")}
                          />
                          Security & Compliances
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/cost.png")}
                          />
                          Cost Benefit Analysis
                        </h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 ml-24">
                <div class="card">
                  <div class="card-header bg1">Planning & Solution Design</div>
                  <div class="card-body cbg1">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/migration.png")}
                          />{" "}
                          Migration plan
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/target.png")}
                          />
                          Target State Architecture
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/resource.png")}
                          />
                          Resource & Schedule
                        </h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3  ml-24">
                <div class="card">
                  <div class="card-header bg2">Migration Execution</div>
                  <div class="card-body cbg2">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/release_management.png")}
                          />{" "}
                          Release Management
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/production.png")}
                          />
                          Production Cutover
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/technical.png")}
                          />
                          Service Transition
                        </h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3  ml-24">
                <div class="card">
                  <div class="card-header bg3">Management</div>
                  <div class="card-body cbg3">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/sla.png")}
                          />{" "}
                          Service Level Agreement
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/monitoring.png")}
                          />
                          Monitoring
                        </h2>
                      </li>
                      <li class="list-group-item">
                        <h2>
                          <img
                            src={require("../assert/images/pages/optimization.png")}
                          />
                          Optimization
                        </h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default CloudServices;

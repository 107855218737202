import Header from "../components/header";
import Footer from "../components/footers";
import { Helmet } from "react-helmet";

function EightA() {
  return (
    <div>
      <Helmet>
        <title>8A</title>
        <meta name="" content="" />
      </Helmet>
      <Header />
      <div className="subpage">
        <section className="bg-white">
          <div className="container" data-aos="fade-up">
            <div className="row d-flex">
              <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                <img
                  className="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/sba8alogo.png")}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-10 col-xl-10">
                <div className="mr-md-3 mr-lg-3">
                  <h1>
                    The Small Business Administration's minority business 8(a)
                  </h1>
                  <p>
                    The Small Business Administration's minority business 8(a)
                    program was established to help small minority firms compete
                    for federal contracts. The 8(a) contract vehicle offers
                    federal government buyers the following advantages:
                  </p>
                  <ul className="list">
                    <li>
                      Contracts can be sole sourced, no competition required up
                      to $4M.
                    </li>
                    <li>
                      Administrative time is reduced resulting in faster awards.
                    </li>
                    <li>Reduces paperwork.</li>
                    <li>
                      8(a) companies are required to perform 51% of the
                      contract. This allows us to partner with and add other
                      companies if additional capabilities are required under a
                      contract.
                    </li>
                    <li>
                      Convenient option to meet the agencies' commitment to
                      small, minority and disadvantaged business development
                      goals.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default EightA;

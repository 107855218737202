import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import byteforza from "../assert/images/byteForza.png";
import facebook from "../assert/images/facebook.svg";
import twitter from "../assert/images/twitter.png";
import linkedin from "../assert/images/linkedin.svg";
import email from "../assert/images/email.svg";
import telephone from "../assert/images/telephone.svg";

function Footer() {
  const today = new Date();
  const yyyy = today.getFullYear();
  return (
    <div className="footer">
      <div className="footerContainer container">
        <img
          src={byteforza}
          alt="byteforza"
          title="byteforza"
          className="fLogo"
        />
        <Row className="mb-5">
          <Col xs="12" sm="12" xxl="3" lg="3" md="3" className="mbSpace">
            <div className="fMenu">
              <h1>Our Services</h1>
              <ul>
                <li>
                  <a href="/app_dev_modernization">App Dev & Modernization</a>
                </li>
                <li>
                  <a href="/cloud_services">Cloud Services</a>
                </li>
                <li>
                  <a href="/DevSecOps_services">DevSecOps</a>
                </li>
                <li>
                  <a href="/big_data_analytics">Big Data and Analytics</a>
                </li>
                <li>
                  <a href="/Testing">Testing</a>
                </li>
                <li>
                  <a href="/RPA">RPA</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs="12" sm="12" xxl="3" lg="3" md="3" className="mbSpace">
            <div className="fMenu">
              <h1>Contract Vehicles</h1>
              <ul>
                <li>
                  <a href="/8a">8A</a>
                </li>
                <li>
                  <a href="/GSAMAS">GSA MAS</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs="12" sm="12" xxl="3" lg="3" md="3" className="mbSpace">
            <div className="fMenu">
              <h1>About Us</h1>
              <ul>
                <li>
                  <a href="/About_ByteForza">About ByteForza</a>
                </li>
                <li>
                  <a href="/Meet_The_Team">Meet The Team</a>
                </li>
                <li>
                  <a href="/News_Events">News/ Events</a>
                </li>
                <li>
                  <a href="/Careers">Careers</a>
                </li>
                <li>
                  <a href="/privacyPolicy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/ContactUs">Contact Us</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs="12" sm="12" xxl="3" lg="3" md="3">
            <div className="fMenu">
              <h1>Location</h1>
              <p className="mb-4">
                21351 Gentry Dr, <br />
                Suite # 265, <br />
                Sterling, VA 20166.
              </p>
                {/* 11260 Roger Bacon Drive, <br />
                Suite # 504B, <br />
                Reston, VA 20190
              </p> */}
              <p className="inlineImg">
                <img src={telephone} alt="telephone" /> 847-890-2921{" "}
              </p>
              <a href="mailto:Support@byteforza.com" className="inlineImg">
                <img src={email} alt="email" /> Support@byteforza.com
              </a>
            </div>
          </Col>
        </Row>
        <div className="btmFooter">
          <div className="btmFooterLeft">
            © {yyyy} ByteForza Technologies. All Rights Reserved.
          </div>
          <div className="btmFooterRight">
            <a href="https://www.facebook.com/byteforza" target={"_blank"}>
              <img src={facebook} alt="facebook" />
            </a>
            <a href="https://twitter.com/byteforza?lang=en" target={"_blank"}>
              <img src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/byteforza"
              target={"_blank"}
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

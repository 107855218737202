import Header from "../components/header";
import Footer from "../components/footers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import email from "../assert/images/email_black.png";
import telephone from "../assert/images/telephone_black.png";
import UserForm from "./userForm";
import Iframe from "../components/iframe";

const iframe =
  '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3099.5655576191944!2d-77.40901802365015!3d39.02522273915111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b639b9edd449cb%3A0x2065d4019006d99!2s21351%20Gentry%20Dr%2C%20Sterling%2C%20VA%2020166%2C%20USA!5e0!3m2!1sen!2sin!4v1722847964403!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

function Contact() {
  return (
    <div>
      <Header />
      <div class="subpage">
        <section class="container">
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <h1>Our Location</h1>
              <div className="mb-5 iframe">
                <Iframe iframe={iframe} />
                <p>
                  <strong>USA</strong>
                </p>
                <p>
                21351 Gentry Dr, <br />
                Suite # 265, <br />
                Sterling, VA 20166.<br /> 
                </p>
                <p className="inlineImg">
                  <img src={telephone} alt="telephone" /> 847-890-2921{" "}
                </p>
                <a href="mailto:Support@byteforza.com" className="inlineImg">
                  <img src={email} alt="email" /> Support@byteforza.com
                </a>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <h1>Get In Touch</h1>
              <UserForm />
            </Col>
          </Row>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;

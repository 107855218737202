import Header from "../components/header";
import Footer from "../components/footers";
import { Helmet } from "react-helmet";

function GSASchedule() {
  return (
    <div>
      <Helmet>
        <title>GSA Schedule 70</title>
        <meta
          name="The GSA Group 70 Information Technology (IT-70)"
          content=""
        />
      </Helmet>
      <Header />
      <div class="subpage">
        <section class="bg-white">
          <div class="container" data-aos="fade-up">
            <div class="row d-flex">
              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/Logo_GSAschedule70.png")}
                />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10">
                <div class="mr-md-3 mr-lg-3">
                  <p>
                    ByteForza Technologies is a holder of GSA’s IT 70 Schedule.
                    The GSA Group 70 Information Technology (IT-70) Federal
                    Supply Schedule offers Federal agencies a streamlined
                    procurement vehicle. Agencies can use this vehicle to obtain
                    services and products for implementing information
                    technology solutions that support organizational goals and
                    business activities.
                  </p>
                  <p>
                    ByteForza holds the following Special Item Numbers (SIN)
                    which qualifies us to provide the listed services under
                    schedule 70:
                  </p>
                  <ul className="list">
                    <li>
                      GSA IT-70 Schedule SIN 132-51 – Information Technology
                      Professional Services
                    </li>
                    <li>ByteForza GSA IT-70 Contract</li>
                    {/* <li>ByteForza GSA IT-70 Contract #:GS-35F-168DA</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default GSASchedule;

import Header from "../components/header";
import Footer from "../components/footers";
import { Helmet } from "react-helmet";

function GSAMAS() {
  return (
    <div>
      <Helmet>
        <title>
           GSA MAS
        </title>
        <meta name="GENERAL SERVICES ADMINISTRATION (GSA) MULTIPLE AWARD SCHEDULE (MAS)" content="" />
      </Helmet>
      <Header />
      <div class="subpage">
        <section class="bg-white">
          <div class="container" data-aos="fade-up">
            <div class="row d-flex">
              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                <img
                  class="img-fluid xs-mt-30 imgR"
                  src={require("../assert/images/GSA_MAS.png")}
                />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10">
                <div class="mr-md-3 mr-lg-3">
                  <p>
                    ByteForza Inc., is a holder of GSA Multiple Award Schedule
                    (MAS) MAS contract.
                  </p>
                  <p>
                    GSA Multiple Award Schedules (MAS) is the largest, most
                    widely used acquisition vehicle in the federal government.
                    GSA MAS is an indefinite delivery/indefinite quantity (IDIQ)
                    multiple award schedule, providing direct access to products
                    and services from certified industry partners.
                  </p>
                  <ul className="list">
                    <li>ByteForza's GSA MAS Contract #:47QTCA24D000L</li>
                    <li>Period of performance: 10/19/2023 to 10/18/2028</li>
                    {/* <li>ByteForza GSA IT-70 Contract #:GS-35F-168DA</li> */}
                  </ul>
                  <b>ByteForza MAS Link</b>
                  <ul>
                    <li>
                      <a href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA24D000L&contractorName=BYTEFORZA+INC.&executeQuery=YES" target="blank">
                        GSA eLibrary Contractor Information
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default GSAMAS;

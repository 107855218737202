import $ from "jquery";

$(document).ready(function () {
  var width = $(window).width();
  if (width < 992) {
    $(".imgBanner").removeClass("full-screen");
    $(".imgBanner").addClass("mobile-screen");
  } else $(this).remove();
});

$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll >= 100) {
    $("#navbarMain").addClass("darkHeader");
  } else {
    $("#navbarMain").removeClass("darkHeader");
  }
});

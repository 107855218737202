import BannerImg1 from "../assert/images/banner1.png";
import BannerImg2 from "../assert/images/banner2.png";
import BannerImg3 from "../assert/images/banner3.jpg";
import BannerImg4 from "../assert/images/banner4.jpg";
import ImgAppDevelopment from "../assert/images/app_development_banner.jpg";
import CloudServices from "../assert/images/cloud_services.jpg";
import DecSecOps from "../assert/images/DecSecOps.jpg";
import BigData from "../assert/images/big_data.jpg";
import Testing from "../assert/images/testing.jpg";
import RPA from "../assert/images/rpa.jpg";
import About from "../assert/images/about-banner.jpg";
import Team from "../assert/images/team_banner.jpg";
import News from "../assert/images/news.jpg";
import Careers from "../assert/images/careers.jpg";
import Contact from "../assert/images/contact-us.jpg";
import Privacypolicy from "../assert/images/Privacy-policy-image.jpg";
import GSAMAS from "../assert/images/GSAMAS_banner.jpg";

const banner = [
  {
    id: 1,
    title:
      "U.S Small Business Administration SBA 8(a) Certified Woman Owned Business with Digital, Business, and Technology Transformation Expertise",
    description:
      "Today we are at the cusp of a major technology transformation in both Federal and Commercial sector.",
    alt: "U.S Small Business Administration SBA 8(a) Certified Woman Owned Business with Digital, Business, and Technology Transformation Expertise",
    imgSource: BannerImg1,
  },
  {
    id: 2,
    title: "Your Trusted Partner for Digital Transformation",
    description:
      "We bring people, business process & technology together for digital empowerment.",
    alt: "Your Trusted Partner for Digital Transformation",
    imgSource: BannerImg2,
  },
  {
    id: 3,
    title: "Be part of something Greater",
    description:
      "Join a digital transformation leader that will engage your mind and inspire your creativity.",
    alt: "Be part of something Greater",
    imgSource: BannerImg3,
  },
  {
    id: 4,
    title: "Dream Big. We make IT a Reality",
    description: "Your Vision, Our Future & Your Potential, Our Passion.",
    alt: "Dream Big. We make IT a Reality",
    imgSource: BannerImg4,
  },
];

const subBanner = [
  {
    id: 1,
    title: "Application Development & Modernization",
    description:
      "Accelerate your Business Growth by Building Cloud-Native Applications",
    alt: "Application Development & Modernization",
    imgSource: ImgAppDevelopment,
    pathName: "/app_dev_modernization",
  },
  {
    id: 2,
    title: "Cloud Services",
    description:
      "Today we are at the cusp of a major technology transformation in both Federal and Commercial sector.",
    alt: "SubBanner 2",
    imgSource: CloudServices,
    pathName: "/cloud_services",
  },
  {
    id: 3,
    title: "DevSecOps",
    description:
      "Integrate security into all stages of the DevOps pipeline – shifting security left ",
    alt: "DevSecOps Services",
    imgSource: DecSecOps,
    pathName: "/DevSecOps_services",
  },
  {
    id: 4,
    title: "Big Data and Analytics",
    description:
      "Next Gen Data Analytics for enabling smart decisions to accelerate growth.",
    alt: "Big Data and Analytics",
    imgSource: BigData,
    pathName: "/big_data_analytics",
  },
  {
    id: 5,
    title: "Testing",
    description:
      "Ensure quicker and frequent software delivery with superior quality.",
    alt: "Ensure quicker and frequent software delivery with superior quality.",
    imgSource: Testing,
    pathName: "/Testing",
  },
  {
    id: 6,
    title: "Robotic Process Automation (RPA)",
    description:
      "Enhance productivity, Eliminate Costs, Drive Innovation. Automate your way to success with Robotic Process Automation.",
    alt: "Robotic Process Automation (RPA)",
    imgSource: RPA,
    pathName: "/RPA",
  },
  {
    id: 7,
    title: "About Us",
    description: "",
    alt: "About ByteForza",
    imgSource: About,
    pathName: "/About_ByteForza",
  },
  {
    id: 8,
    title: "Our Leadership Team",
    description: "",
    alt: "Our Leadership Team",
    imgSource: Team,
    pathName: "/Meet_The_Team",
  },
  {
    id: 9,
    title: "8(a)",
    description: "The Small Business Administration's minority business 8(a)",
    alt: "The Small Business Administration's minority business 8(a)",
    imgSource: Team,
    pathName: "/8a",
  },
  {
    id: 10,
    title: "GSA Schedule 70",
    description: "The GSA Group 70 Information Technology (IT-70)",
    alt: "GSA Schedule 70",
    imgSource: Team,
    pathName: "/GSA_Schedule_70",
  },

  {
    id: 11,
    title: "News & Events",
    description: "",
    alt: "News & Events",
    imgSource: News,
    pathName: "/News_Events",
  },
  {
    id: 12,
    title: "Careers",
    description: "",
    alt: "ByteForza Careers",
    imgSource: Careers,
    pathName: "/Careers",
  },
  {
    id: 13,
    title: "Contact Us",
    description: "",
    alt: "Contact Us",
    imgSource: Contact,
    pathName: "/ContactUs",
  },
  {
    id: 14,
    title: "Privacy Policy",
    description: "",
    alt: "Privacy Policy",
    imgSource: Privacypolicy,
    pathName: "/privacyPolicy",
  },
  {
    id: 15,
    title:
      "GENERAL SERVICES ADMINISTRATION (GSA) MULTIPLE AWARD SCHEDULE (MAS)",
    description: "",
    alt: "GSA MAS",
    imgSource: GSAMAS,
    pathName: "/GSAMAS",
  },
];

export { banner, subBanner };
